.login-back {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #c4c4c4;
  color: #fff;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form{
  background-color: #444 !important;
}


.login-page-form {
  padding: 20px;
}

.login-page-form input[type="Email"]{
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: none;
  color: #000000;
}

.login-page-form input[type="password"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: none;
  color: #080808;
}

.login-page-form  button[type="submit"] {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #B91820;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

.login-page-form  button[type="submit"]:hover {
  background-color: #770d12;
}

.input-login {
  margin-bottom: 10px;
  height: 50px;
  font-size: 20px !important;
  font-weight: 500 !important;
}

.error-message{
  display: flex;
  justify-content: center;
  color: red;
  margin-bottom: 14px;
}