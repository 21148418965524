body {
  margin: 0;
  padding: 0;
}

.page {
  display: flex;
}

hr{
  margin: 8px 0 8px 0 !important;
  padding: 0 !important;
}

.btn-open-pdf {
  margin-top: 31px;
}

.center-spinner { 
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px; 
}

.content {
  width: calc(100vw - 218px);
  height: calc(100vh);
  margin-left: 200px;
}

.content-bi {
  width: calc(100vw - 218px);
  height: 100vh;
}

.content-research {
  width: 100vw;
}

.sair-btn{
  color: #b91820;
}

/* SIDEBAR */
.sidebar {
  position: fixed;
  display: flex !important;
  align-items: flex-start !important;
  width: 200px;
  background-color: #c4c4c4;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
  box-shadow: 1px 0 4px rgba(65, 65, 65, 0.575);
}
.sidebar-logo {
  width: 200px;
  margin: 10px 10px 0 0;
  padding: 10px 10px 0 0;
}

.sidebar-header {
  width: 100%;
}
.sidebar-footer {
  width: 100%;
}

.sidebar-header-content {
  display: flex;
  justify-content: space-between;
  /* cursor: pointer; */
}

h4 {
  margin-bottom: 0 !important;
}

.sidebar-menu-item {
  text-decoration: none !important;
  padding-left: 20px;
  margin-top: 4px;
  font-size: 17px;
}

.sidebar-menu-item:hover {
  background-color: rgb(113, 114, 116);
}

.menu-i {
  color: rgb(37, 37, 37);
}

.menu-i:hover {
  color: #fdfcfc;
}

a {
  text-decoration: none !important;
}

.sidebar-arrow {
  margin-right: 10px;
  color: aliceblue;
}

.sidebar-header-text {
  margin-left: 20px;
  color: #b91820;
  text-shadow: 1px 1px 0px rgb(139, 139, 139);
}

.dash {
  height: calc(100vh - 67px);
  width: 100vw;
}

.header_modules {
  margin: 20px 10px 0 20px;
  color: rgb(34, 32, 32);
  font-size: 30px;
  font-weight: 800;
  padding-bottom: 50px;
}

.header_modules_filtered {
  margin: 20px 10px 0 20px;
  color: rgb(34, 32, 32);
  font-size: 30px;
  font-weight: 800;
  padding-bottom: 10px;
}

.product-title {
  text-decoration: underline #b91820;
  float: left;
}

.action-btn {
  margin-left: 10px;
}

.btn-add-roles {
  float: right;
  margin-left: 6px;
}

.alert {
  position: relative;
}

.spinner {
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}



.table_funcoes {
  margin: 10px 10px 20px 20px;
}
.status {
  margin: 0;
  font-size: 18px;
}

.badge {
  margin: 0;
  padding: 0;
}

/* STATUS REPORTS */

.custom-badge-report {
  padding: 4px 8px 4px 8px;
  border-radius: 14px;
  font-weight: bold;
}

.MuiTableCell-body-report {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.report-recebido {
  background-color: rgb(108, 117, 125);
  color: white;
  width: 100%;
}

.report-desenvolvimento {
  background-color: rgb(39, 7, 246);
  color: white;
  width: 100%;
}

.report-analise {
  background-color: rgb(255, 193, 7);
  color: black;
  width: 100%;
}

.report-concluido {
  background-color:  rgb(25, 135, 84);
  color: white;
}

.report-fechado {
  background-color:  rgb(220, 53, 69);
  color: white;
}

/* STATUS VENDAS */

.custom-badge {
  padding: 4px 8px 4px 8px;
  border-radius: 14px;
  font-weight: bold;
}

.MuiTableCell-body {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.venda-pedido-recebido {
  background-color: rgb(108, 117, 125);
  color: white;
  width: 100%;
}

.venda-analise-viabilidade {
  background-color: rgb(255, 193, 7);
  color: rgb(27, 27, 27);
  width: 100%;
}

.venda-proposta-apresentada {
  background-color: rgb(25, 135, 84);
  color: rgb(255, 255, 255);
}

.venda-escopo-analise {
  background-color: rgb(255, 193, 7);
  color: rgb(27, 27, 27);
}

.venda-em-analise {
  background-color: rgb(255, 193, 7);
  color: rgb(27, 27, 27);
}

.venda-declinada {
  background-color: rgb(220, 53, 69);
  color: rgb(255, 255, 255);
}

.venda-reprovada {
  background-color: rgb(220, 53, 69);
  color: rgb(255, 255, 255);
}

.venda-cancelada {
  background-color: rgb(220, 53, 69);
  color: rgb(255, 255, 255);
}

.venda-aprovada {
  background-color: rgb(25, 135, 84);
  color: white;
  width: 100%;
}

.form-label {
  font-weight: 500;
}

.groups-error {
  color: red;
  display: flex;
  justify-content: center;
}

.grupo-list-item {
  display: flex !important;
  justify-content: space-between !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

/* FORM */
.btn-controls {
  display: flex;
  justify-content: flex-end;
}

.link-form {
  padding-left: 20px;
}

#btn-save-ranking {
  margin-top: 20px;
  width: 300px;
}

#reportContainer {
  margin-left: 200px !important;
  width: 100%;
  height: 100vh !important;
}

.no-spinners {
  appearance: textfield;
}
.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

