/* Estilos para o proposal-card */
.proposal-card {
  border: 2px solid transparent;
  height: 270px;
  background: black;
  margin: 10px;
  max-width: 100% ; /* RESPONSIVIDADE TA AQUI , mudei de width para max*/
  display: inline-block;
  vertical-align: top;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding-left: 12px;
  padding-right: 12px;
  background-color: white;
  cursor: pointer;
  transition: box-shadow 0.2s ease; /* Adicionando transição de destaque */
}

.proposal-card:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Destaque ao passar o mouse */
  /* background-image: url('https://grupovolpe.ind.br/wp-content/uploads/2022/11/Logo-GRUPO-VOLPE-PNG.png'); */
}

.card-logo-container{
  display: flex;
  justify-content: center;
}
.card-logo {
  padding-top: 6px;
  height: 40px; /* Ajuste conforme necessário */
  object-fit: fill;
}
.subtitulo {
  font-family: "Open Sans Condensed", "Arial", sans-serif;
  text-align: left;
  padding: 5px;
  font-weight: 600;
  font-size: 12pt;
  margin: 0;
}

.num-proposal {
  font-family: "Open Sans Condensed", "Arial", sans-serif;
  text-align: center;
  font-size: 10pt;
  text-decoration: underline; 
  padding-top: 5px;
  padding-left: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; /* Ajuste para tornar o elemento responsivo */
  position: relative;
 
}

.num-proposal:hover::after {
  content: attr(title);
  white-space: normal;
  overflow: visible;
  z-index: 1;
  background-color: white; /* Opcional: Adicione uma cor de fundo para melhor visibilidade */
  position: absolute;
  top: 100%; /* Posiciona a dica abaixo do texto */
  left: 0;
  max-width: none;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}


.negrito {
  font-weight: bold;
}

.card-row-space {
  padding-top: 5px;

}
.card-content {
  padding-top: 2px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  padding-left: 2px;
  height: fit-content;
  font-size: 9pt;
  background-color: #ffffff;
  

  b {
    font-weight: bold;
  }
}

.card-content .alinha {
  display: inline-flex;
  align-content: space-between;
}

.card-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.card-actions {
  text-align: center;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
}

.button-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  max-width: 100%;
  
}

.button-actions button {
  border: 1px transparent;
  padding: 2px;
  border-radius: 3px;
  cursor: pointer;
  background-color: transparent;
  margin: 0 5px;
  flex: 1;
  max-width: 100%; /* Ajuste para tornar os botões responsivos */
}

.card-actions > * {
  margin: 5px 0;
}

.button-actions .button {
  display: flex;
  align-items: center;
}

.button-actions .button svg {
  margin-right: 5px;
}

.button-actions .button + .button {
  margin-left: 10px;
}

/* Adiciona uma largura máxima para o Rating para torná-lo responsivo */
.MuiRating-root {
  max-width: 100%;
}

.button {
  border: 1px transparent;
  padding: 2px;
  border-radius: 3px;
  cursor: pointer;
  background-color: transparent;
}

button:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Destaque ao passar o mouse */
}

.button-container {
  display: inline-block;
  align-items: center;
  padding-left: 30%;
  padding-top: 19px;
  justify-content: space-between;
}

.file-viewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s ease;
}

.file-viewer.open {
  visibility: visible;
  opacity: 1;
}

/* Estilo para o PDFViewer e ExcelViewer dentro do viewer */
.viewer-content {
  width: 80%;
  height: 80%;
  background-color: white;
  position: relative;
}