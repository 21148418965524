.acessos-column {
    align-Items:space-between;
    display:flex;
    padding-bottom:12px;
    justify-Content: space-around;
    margin-right:6px ;
    
}

.form-rows {
    margin: 0px 20px 20px 30px;
    
}

.form-container-buttons {
    display: flex;
    padding-top: 15px;
    gap: 10px;
    justify-content: end;
};