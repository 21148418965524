.changepass-container{
  width: calc(100vw - 200px) !important;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #b1b0b0;
  margin: 0 !important;
  padding: 0 !important;
}

.changepassord-back {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #e0dddd;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}


.changepass-card{
  width: 500px;

}

.changepass-page-form {
  background-color: #343a40;
  padding: 20px;
  
}

.changepass-page-form input[type="password"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: none;
  background-color: #ffffff;
}

.changepass-page-form  button[type="submit"] {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #B91820;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}
.changepass-page-form  button[type="submit"]:hover {
  background-color: #770d12;
}


.changepass-page-form  button {
  width: 100%;
  padding: 10px;
  
  border-radius: 5px;
  border: none;
  background-color: #B91820;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}
.changepass-page-form  button:hover {
  background-color: #770d12;
}


.warning-message{
  display: flex;
  justify-content: center;
  color: rgb(51, 129, 6);
  margin-bottom: 14px;
}