.question {
    font-size: larger;
    font-weight: 800;
}

.score-button {
    border: none;
    border-radius: 50%;
    width: 2em;
    height: 2em;
    margin-right: 5px;
    font-size: 26px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 2px 0 4px rgba(65, 65, 65, 0.575);
    /* flex: 1; */
  display: flex;
  flex-direction: column;
  text-align: center;
}

.red {
    background-color: rgb(253, 82, 82);
    color: white;
}

.yellow {
    background-color: rgb(252, 203, 40);
    color: white;
}

.green {
    background-color: rgb(31, 146, 31);
    color: white;
}

.selected {
    border: 2px solid blue;
}

.score-button.selected {
    border-color: #1e2221ab; /* Change the color as needed */
    border-width: 3px;
    box-shadow: 2px 0 4px rgba(32, 29, 29, 0.575);
  }

.score-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.score-button {
    /* Remaining button styles */
    margin-right: 10px;
    /* Adjust the margin as needed */

}

.research-service {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
}

@media (max-width: 600px) {
    .scores-container {
      flex-direction: column;
    }
  
    .score-button {
      margin-bottom: 10px;
    }
  }