.research-header {
    padding: 2px;
    margin-bottom: 10px;
    margin-top: 2px;
    border-radius: 10px;
    border-width: 2px;
    border-style: solid;
    border-color: black;
    background-color:  #fcfcff;
}

.research-warning {
    color: red;
}

.research {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.app-header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.employee-card-body {
    padding: 10px;
    background-color:  #fcfcff;
}

.research-employee {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.form-space-betwwen {
    display: flex;
    justify-content: space-between;
}

.custom-heading {
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    font-size: 2rem;
}

.radio-employee{
    display: flex;
    flex-direction: column;

}

.form-check-inline{
    margin-right: 0 !important;
}

.thanks{
    display: flex;
    align-items: center;
    height: 100vh;
}